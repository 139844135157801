import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import requireEspace, { EspaceProps } from '../../../../utils/requireEspace';
import { UserProps } from '../../../../utils/requireUser';
import Produits from './index';

const PageProduitsEnStock: FC<PageProps & EspaceProps & UserProps> = props => (
  <Produits {...props} pageContext={{ tab: 2 }} />
);

export default requireEspace(PageProduitsEnStock);
